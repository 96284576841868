// src/App.js

import React from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Footer from './components/Footer';
import ContactPage from './components/ContactPage'; // Import the ContactPage component
import ScrollAnimation from './components/ScrollAnimation'; // Import the ScrollAnimation component
import '@fortawesome/fontawesome-free/css/all.css';

function App() {
  const handleContactClick = (event) => {
    event.preventDefault();
    const contactSection = document.getElementById('contact');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <Header handleContactClick={handleContactClick} />
      <HeroSection />
      <Features />
      <ContactPage />
      <Footer />
      <ScrollAnimation />
    </div>
  );
}

export default App;

import React from 'react';
import './Header.css';

const Header = () => {
  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="header">
      <div className="logo">ASSESSA</div>
      <nav className="nav">
        <button className="nav-button" onClick={() => handleScroll('about')}>About Us</button>
        <button className="nav-button" onClick={() => handleScroll('features')}>Features</button>
        <button className="nav-button" onClick={() => handleScroll('pricing')}>Pricing</button>
        <button className="nav-button" onClick={() => handleScroll('contact')}>Contact Us</button>
      </nav>
      <div className="auth-buttons">
        <button className="signup-button">Sign Up</button>
        <button className="login-button">Login</button>
      </div>
    </header>
  );
};

export default Header;
